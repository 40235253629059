import { userService } from "services";

const strings = {
  ro: {
    HARTA: "Harta",
    CATALOG_NAVE: "Catalog nave",
    DISPECERAT: "Dispecerat",
    SITUATIE_FLOTA: "Situatie flota",
    JURNAL_BORD: "Jurnal bord",
    JURNAL_MASINA: "Jurnal masina",
    AGENTI: "Agenti",
    MENTENANTA_NEPROP: "Mentenanta neprop.",
    ACTIVITATI: "Activitati",
    ISTORIC: "Istoric",
    DIVERSE: "Diverse",
    RAPOARTE: "Rapoarte",
    PLANURI: "Planuri",
    CONVOI: "Convoi",
    CONVOAIE: "Convoaie",
    BARJE: "Barje",
    PROPULSATE: "Propulsate",
    ALTE_NAVE: "Alte nave",
    UNITATI: "Unitati",
    AVIZARI: "Avizari",
    COTE_APE: "Cote ape",
    REALIZARI_ZILNICE: "Realizari zilnice",
    PROBLEME_NAVA: "Probleme nava",
    EXPIRARI_PERMISE: "Expirari permise",
    BARJE_CAPACE: "Barje cu capace",
    ECHIPAJ_NAVA: "Echipaj nava",
    MODIFICARI: "Modificari",
    SITUATIE_REALIZARI: "Situatie realizari",
    FILTRARI: "Filtrari",
    NAVA: "Nava",
    TIP_DOCUMENT: "Tip document",
    FILTREAZA: "Filtreaza",
    RESET: "Reset",
    DOCUMENTE_TRANSPORT: "Documente transport",
    ADAUGA_DOCUMENT: "Adauga document",
    EROARE: "Avem eroare!",
    FARA_DATE: "Nu s-au gasit date!",
    VIZUALIZARE: "Vizualizare",
    MARFA: "Marfa",
    SORT_MARFA: "Sort marfa",
    PORT_PLECARE: "Port plecare",
    PORT_SOSIRE: "Port sosire",
    CANTITATE: "Cantitate",
    CANT: "Cant.",
    TIP_DOC: "Tip doc",
    NR_DOC: "Nr. doc",
    DATA_DOC: "Data doc",
    MOD_GENERARE: "Mod generare",
    SCANAT: "Scanat",
    GENERAT: "Generat",
    LOCATIE: "Locatie",
    BL: "BL",
    PLAN_TRANSPORT: "Plan transport",
    DATA: "Data",
    NRBL: "NRBL",
    NR_BUCATI: "Nr bucati",
    GREUTATE_TO: "Greutate TO",
    GREUTATE_PESCAJ: "Greutate Pescaj",
    PESCAJ: "Pescaj",
    EXPEDITOR: "Expeditor",
    DESTINATAR: "Destinatar",
    AVIZAT: "Avizat",
    DENUMIRE_MARFA: "Denumire marfa",
    ANEXE_SIGILII: "Anexe sigilii",
    LOC_INTORCMIRE: "Loc intocmire",
    OBSERVATII: "Observatii",
    INCARCA_DOCUMENT: "Incarca doc.",
    GENEREAZA_PDF: "Genereaza pdf",
    SALVEAZA: "Salveaza",
    SALVAT: "Salvat",
    INAPOI: "Inapoi",
    VIZUALIZARE_BARJE: "Vizualizare barje",
    ACTIVITATI_BARJE: "Activitati barje",
    ACTIVITATI_PROPULSATE: "Activitati propulsate",
    ACTIVITATI_PROPULSOARE: "Activitati propulsoare",
    PROPULSOARE: "Propulsoare",
    STARE: "Stare",
    POZITIE: "Pozitie",
    PROPULSOR: "Propulsor",
    AMENAJARE: "Amenajare",
    TIP_NAVA: "Tip nava",
    TOATE: "Toate",
    NEPROPULSATE: "Nepropulsate",
    PROPRIETAR: "Proprietar",
    DENUMIRE: "Denumire",
    ANR: "ANR",
    ENI: "ENI",
    CAPACITATE: "Capacitate",
    DATA_START: "Data start",
    DATA_END: "Data sfarsit",
    NAVA_NEPROPULSATA: "Nava nepropulsata",
    ACTIVITATE: "Activitate",
    ADD_JURNAL: "Adauga jurnal",
    ACTIVITATE_SIMULTANA: "Activitate simultana",
    DIRECTIE: "Directie",
    DE_LA: "De la",
    PANA_LA: "Pana la",
    STERGE: "Sterge",
    BARJA: "Barja",
    PESCAJ_MAXIM: "Pescaj maxim",
    JURNAL_PLAN: "Jurnal plan",
    NUMAR_JURNAL: "Numar jurnal",
    AVAL: "Aval",
    AMONTE: "Amonte",
    POZITIE_START: "Pozitie start (de la km)",
    ORA_STOP: "Ora stop",
    DURATA: "Durata",
    JURNAL_UNITATI: "Jurnal unitati",
    ADAUGA: "Adauga",
    IMPORT: "Import",
    ADD_JURNAL_UNITATE: "Adauga jurnal unitate",
    ADD_MENTENANTA: "Adauga mentenanta",
    DATA_CONSTATARE: "Data constatare",
    DATA_REZOLVARE: "Data rezolvare",
    LOC_ACTIVITATE: "Loc activitate",
    REZOLVAT: "Rezolvat",
    CURATAT_DE: "Curatat de",
    TIP_CURATENIE: "Tip curatenie",
    STATUS: "Status",
    CURAT: "Curat",
    MURDAR: "Murdar",
    DATA_CURATENIE: "Data curatenie",
    DEPARTAMENT: "Departament",
    CHIMICE: "Chimice",
    METALE: "Metale",
    AGRICOLE: "Agricole",
    CLIENT: "Client",
    VALIDEAZA_MODIFICARI: "Valideaza modificari",
    NUMAR: "Numar",
    DATA_ADAUGARE: "Data adaugare",
    MEDIU: "Mediu",
    PARTENER: "Partener",
    CLIENTUL_CLIENTULUI: "Clientul clientului",
    TARA_PLECARE: "Tara plecare",
    TARA_SOSIRE: "Tara sosire",
    ADAUGARE_PLAN: "Adaugare plan",
    TIP_PRESTATIE: "Tip prestatie",
    VALABILITATE: "Valabilitate",
    CARACTERISTICI_MARFA: "Caracteristici marfa",
    NUMAR_LOTURI: "Numar loturi",
    DETALIU_MARFA: "Detaliu marfa",
    INCARCARE: "Incarcare",
    DESCARCARE: "Descarcare",
    PORT_INCARCARE: "Port incarcare",
    DANA_INCARCARE: "Dana incarcare",
    IEY_CAN_START: "Ley/can start",
    IEY_CAN_STOP: "Ley/can stop",
    CU_MANEVRA_INCARCARE: "Cu manevra incarcare",
    FIRMA_MANEVRA: "Firma manevra",
    CU_SURVERY_INCARCARE: "Cu survery incarcare",
    ETA_START: "ETA Start",
    ETA_STOP: "ETA Stop",
    CU_MANEVRA_DESCARCARE: "Cu manevra descarcare",
    CU_SURVEY_DESCARCARE: "Cu survey descarcare",
    ACTIVITATI_ALTE_NAVE: "Activitati alte nave",
    NOMINARE: "Nominare",
    ORA: "Ora",
    ACTIVITATI_CONVOAIE: "Activitati convoaie",
    CONVOAIE_AVAL: "Convoaie aval",
    CONVOAIE_AMONTE: "Convoaie amonte",
    EDITARE_ACTIVITATE: "Editare activitate",
    AFISARE_HARTA: "Afisare harta",
    MOMENT: "Moment",
    PORT: "Port",
    COTA: "Cota",
    TENDINTA: "Tendinta",
    SISTEM: "Sistem",
    GRAVITATE: "Gravitate",
    USOR: "Usor",
    GRAV: "Grav",
    INDISPONIBIL: "Indisponibil",
    DATA_RAPORTARE: "Data raportare",
    REPARAT_LA_BORD: "Reparat la bord",
    SANTIER_NAVAL: "Santier naval",
    RAPORTAT_DE: "Raportat de",
    ADD_PROBLEMA: "Adauga problema",
    CONSTATARE: "Constatare",
    PLANIFICARE: "Planificare",
    IN_REPARATIE: "In reparatie",
    REPARATIE_FINALIZATA: "Reparatie finalizata",
    DATA_START_PLANIFICARE: "Data start planificare",
    DATA_STOP_PLANIFICARE: "Data stop planificare",
    DATA_START_REPARATIE: "Data start reparatie",
    DATA_STOP_REPARATIE: "Data stop reparatie",
    LUNI_GARANTIE: "Luni garantie",
    PROBLEMA: "Problema",
    NR_CONTRACT: "Nr. contract",
    DESTINATIE: "Destinatie",
    ETA: "ETA",
    DOCUMENTE: "Documente",
    DETALII: "Detalii",
    NAVE: "Nave",
    SITUATIE: "Situatie",
    ADAUGA_JURNAL_BORD: "Adauga jurnal bord",
    ADAUGA_JURNAL_UNITATE: "Adauga jurnal unitate",
    ADAUGA_JURNAL_MASINA: "Adauga jurnal masina",
    EDIT_JURNAL_MASINA: "Edit jurnal masina",
    ACTE_NAVA: "Acte nava",
    ADAUGA_ACTE_NAVA: "Adauga acte nava",
    EDIT_ACTE_NAVA: "Edit acte nava",
    ADAUGA_MENTENANTA: "Adauga mentenanta",
    EDITARE_MENTENANTA: "Editare mentenanata",
    ISTORIC_UNITATI: "Istoric unitati",
    ISTORIC_ACTIVITATI: "Istoric activitati",
    ADAUGA_PROBLEMA: "Adauga problema",
    EDIT_PROBLEMA: "Editare problema",
    EXIPRARI_PERMISE: "Expirari permise",
    BARJE_CU_CAPACE: "Barje cu capace",
    ADAUGA_PLAN: "Adauga plan",
    EDIT_PLAN: "Editare plan",
    EDIT_ACTIVITATE: "Editare activitate",
    LISTA_PLANURI: "Lista planuri",
    TESTE: "Teste",
    SETARI: "Setari",
    GENERALE: "Generale",
    NUMAR_RANDURI_IMPLICIT: "Numar de randuri implicit",
    DIMENSIUNE_FONT: "Dimensiune font",
    MIC: "Mic",
    NORMAL: "Normal",
    MARE: "Mare",
    DANA_SOSIRE: "Dana descarcare",
    EXTRA: "Extra",
    PREFERINTE_HARTA: "Preferinte harta",
    AFISEAZA_CANTITATEA: "Afiseaza cantitatea",
    AFISEAZA_MARFA: "Afiseaza marfa",
    AFISEAZA_DOCUMENTE: "Afiseaza documente",
    NUMAR_CONTRACT: "Numar contract",
    DOWNLOAD: "Descarca",
    IMPINGATOR: "Impingator",
    STOC_CONSUM_ZILNIC: "Stoc / Consum zilnic",
    RAPORT_CONSUM_PERIOADA: "Raport consum perioada",
    STOC_MOTORINA: "Stoc motorina",
    STOC_ULEI: "Stoc ulei",
    CONSUM_MOTORINA: "Consum motorina",
    CONSUM_ULEI: "Consum ulei",
    ORE_MARS: "Ore mars",
    ORE_MANEVRA: "Ore manevra",
    ORE_NOAPTE: "Ore noapte",
    KM_PARCURSI: "Km Parcursi",
    CAPACITATE_TANC: "Capacitate tanc",
    STOC_PRECEDENT_ULEI: "Stoc precedent ulei",
    STOC_PRECEDENT_MOTORINA: "Stoc precedent motorina",
    DURATA_CORECTATA: "Durata corectata",
    AGENT: "Agent",
    PERIOADA: "Perioda",
    ZI: "Zi",
    WORKDATASTART: "WORKDATASTART",
    WORKDATASTOP: "WORKDATASTOP",
    STOPDATASTART: "STOPDATASTART",
    STOPDATASTOP: "STOPDATASTOP",
    OPERATOR: "Operator",
    ADAUGA_LOT: "Adauga LOT",
    CANT_NOMINARE: "Cant. Nominare",
    DATA_NOMINARE: "Data Nominare",
    CU_SUBSTITUT: "Cu Substitut",
    OK_TEHNIC: "Ok Tehnic",
    OK_CURATENIE: "Ok Curatenie",
    OK_ACTE: "Ok Acte",
    DATA_ACTIVITATE: "Data activitate",
    RESPONSABIL: "Responsabil",
    OPERATIV_PRINCIPAL: "Operativ Principal",
    TIP: "Tip",
    VALOARE: "Valoare",
    NAVA_MARITIMA: "Nava maritima",
    OPERATIV_CERERE: "Operativ cerere",
    LISTA_POZITII: "Lista pozitii",
    POZITII: "Pozitii",
    LOTURI_MARFA: "Loturi marfa",
    TIP_OPERATIE: "Tip operatie",
    TIP_IMPACHETARE: "Tip impachetare",
    NR_LOTURI: "Nr. Loturi",
    LISTA_NAVEALOCATE: "Lista nave alocate",
    NAVE_ALOCATE: "Nave alocate",
    NR_BARJA: "Nr. Barja",
    NR_PLAN: "Nr. Plan",
    LOGHEAZATE: "Logheaza-te în contul tau",
    PAROLA: "Parola",
    AI_UITAT_PAROLA: "Ai uitat parola?",
    LOC_DETALIU: "Loc detaliu",
    CU_DETALII: "Cu detalii",
    INFORMATII: "Informatii",
    DATASIORA: "Data si ora",
    SORT: "Sort",
    UTILIZATOR: "Utilizator",
    DATA_EMITERE: "Data emitere",
    PLAN_PRINCIPAL: "Plan principal",
    ADAUGARE_NAVE_ALOCATE: "Adaugare nave alocate",
    NAVA_PROPULSATA: "Nava propulsata",
    TOATE_COTELE: "Toate cotele",
    PROBLEME_LA_BORD: "Probleme la bord",
    INFORMATII_NAVA: "Informatii nava",
    STARE_CURATENIE: "Stare curatenie",
    DOCUMENT: "Document",
    NR_POLITA: "Nr polita",
    DESCRIERE: "Descriere",
    TIP_ACT: "Tip act",
    EXPIRA20: "Expira 20",
    VALABIL: "Valabil",
    STOC_ORA_7: "Stoc ora 7",
    SITUATIE_CONVOAIE: "Situatie convoaie",
    FARA_NAVE: "Nu s-a gasit nicio nava",
    FARA_DOCUMENTE: "Nu s-a generat documentul",
    SELECTATI_DATA: "Selectati data",
    CAUTA: "Cauta...",
    AFISARE_COLOANE: "Afisare coloane",
  },
  en: {
    SITUATIE_CONVOAIE: "Convoy situation",
    STOC_ORA_7: "7hour stock",
    VALABIL: "Available",
    EXPIRA20: "Expire 20",
    TIP_ACT: "Document type",
    DESCRIERE: "Description",
    NR_POLITA: "Doc. no.",
    DOCUMENT: "Document",
    STARE_CURATENIE: "Maintenance status",
    PROBLEME_LA_BORD: "Problems",
    INFORMATII_NAVA: "Ship informations",
    TOATE_COTELE: "All levels",
    NAVA_PROPULSATA: "Propeller",
    ADAUGARE_NAVE_ALOCATE: "Add ships",
    PLAN_PRINCIPAL: "Master plan",
    DATA_EMITERE: "Release date",
    UTILIZATOR: "Username",
    SORT: "Sort",
    DATASIORA: "Date and time",
    INFORMATII: "Informations",
    CU_DETALII: "With details",
    LOC_DETALIU: "Detail Place",
    AI_UITAT_PAROLA: "Have you forgotten the password?",
    LOGHEAZATE: "Log in to your account",
    PAROLA: "Password",
    NR_PLAN: "Plan no.",
    NR_BARJA: "Barje no.",
    NAVE_ALOCATE: "Ships",
    LISTA_NAVEALOCATE: "Ships list",
    NR_LOTURI: "Lot no.",
    TIP_IMPACHETARE: "Packing type",
    TIP_OPERATIE: "Operation type",
    POZITII: "Items",
    LISTA_POZITII: "Items list",
    LOTURI_MARFA: "Goods lots",
    OPERATIV_CERERE: "Operativ cerere",
    NAVA_MARITIMA: "Ship",
    VALOARE: "Value",
    TIP: "Type",
    OPERATIV_PRINCIPAL: "Main Operative",
    RESPONSABIL: "In charge",
    DATA_ACTIVITATE: "Activity Date",
    OK_ACTE: "Documents Ok",
    OK_CURATENIE: "Cleaning Ok",
    OK_TEHNIC: "Technical OK",
    CU_SUBSTITUT: "With Substitute",
    DATA_NOMINARE: "Date",
    CANT_NOMINARE: "Quantity",
    ADAUGA_LOT: "Add LOT",
    STOPDATASTOP: "STOPDATASTOP",
    STOPDATASTART: "STOPDATASTART",
    WORKDATASTOP: "WORKDATASTOP",
    STOC_PRECEDENT_MOTORINA: "Previous diesel stock",
    STOC_PRECEDENT_ULEI: "Previous oil stock",
    CAPACITATE_TANC: "Tank capacity",
    KM_PARCURSI: "Km reaveled",
    ORE_NOAPTE: "Night hours",
    ORE_MANEVRA: "Maneuver hours",
    ORE_MARS: "March hours",
    CONSUM_ULEI: "Oil consumtion",
    CONSUM_MOTORINA: "Diesel consumtion",
    STOC_ULEI: "Oil stock",
    STOC_MOTORINA: "Diesel stock",
    RAPORT_CONSUM_PERIOADA: "Period consumption report",
    STOC_CONSUM_ZILNIC: "Daily Stock / Consumption ",
    DOWNLOAD: "Download",
    NUMAR_CONTRACT: "Contract number",
    AFISEAZA_CANTITATEA: "Show quantity",
    AFISEAZA_MARFA: "Show goods",
    AFISEAZA_DOCUMENTE: "Show documents",
    PREFERINTE_HARTA: "Map preferences",
    MIC: "Small",
    NORMAL: "Normal",
    MARE: "Large",
    EXTRA: "Extra large",
    DIMENSIUNE_FONT: "Font size",
    NUMAR_RANDURI_IMPLICIT: "Default number of rows",
    GENERALE: "General",
    SETARI: "Settings",
    TESTE: "Tests",
    LISTA_PLANURI: "List of plannings",
    EDIT_ACTIVITATE: "Edit activity",
    EDIT_PLAN: "Edit planning",
    ADAUGA_PLAN: "Add planning",
    BARJE_CU_CAPACE: "Barges with covers",
    EXIPRARI_PERMISE: "Expired allows", //todo de gasit traducere
    EDIT_PROBLEMA: "Edit problem",
    ADAUGA_PROBLEMA: "Add problem",
    ISTORIC_ACTIVITATI: "Activity's history",
    ISTORIC_UNITATI: "Unit's history",
    EDITARE_MENTENANTA: "Edit mentenance",
    ADAUGA_MENTENANTA: "Add mentenanace",
    EDIT_ACTE_NAVA: "Edit ship document",
    ADAUGA_ACTE_NAVA: "Add ship document",
    ACTE_NAVA: "Ship's documents",
    EDIT_JURNAL_MASINA: "Edit machine's log",
    ADAUGA_JURNAL_MASINA: "Add machine's log",
    ADAUGA_JURNAL_BORD: "Add ship's register",
    ADAUGA_JURNAL_UNITATE: "Add unit's register",
    HARTA: "Map",
    CATALOG_NAVE: "Ship catalog",
    DISPECERAT: "Dispatch",
    SITUATIE_FLOTA: "Fleet status",
    JURNAL_BORD: "Ship's register",
    JURNAL_MASINA: "Machine's log",
    AGENTI: "Agents",
    MENTENANTA_NEPROP: "Unpowered maintenance",
    ACTIVITATI: "Activities",
    ISTORIC: "History",
    DIVERSE: "Misc.",
    RAPOARTE: "Raports",
    PLANURI: "Planning",
    CONVOI: "Convoy",
    CONVOAIE: "Convoys",
    BARJE: "Barges",
    PROPULSATE: "Powered",
    ALTE_NAVE: "Other",
    UNITATI: "Units",
    AVIZARI: "Notifications",
    COTE_APE: "Water levels",
    REALIZARI_ZILNICE: "Daily achievements",
    PROBLEME_NAVA: "Ship issues",
    EXPIRARI_PERMISE: "License expiration",
    BARJE_CAPACE: "Lid barges",
    ECHIPAJ_NAVA: "Crew",
    MODIFICARI: "Changes",
    SITUATIE_REALIZARI: "Achievements",
    FILTRARI: "Filters",
    NAVA: "Ship",
    TIP_DOCUMENT: "Document type",
    FILTREAZA: "Filter",
    RESET: "Reset",
    DOCUMENTE_TRANSPORT: "Transport documents",
    ADAUGA_DOCUMENT: "Add document",
    EROARE: "Error!",
    FARA_DATE: "No data found",
    VIZUALIZARE: "View",
    MARFA: "Goods",
    SORT_MARFA: "Goods category",
    PORT_PLECARE: "Loading Port",
    PORT_SOSIRE: "Discharging Port",
    CANTITATE: "Quantity",
    CANT: "Quant.",
    TIP_DOC: "Doc. Type",
    NR_DOC: "No. doc.",
    DATA_DOC: "Date doc",
    MOD_GENERARE: "Generate type",
    SCANAT: "Scanned",
    GENERAT: "Generated",
    LOCATIE: "Location",
    BL: "BL",
    PLAN_TRANSPORT: "Transportation plan",
    DATA: "Date",
    NRBL: "NRBL",
    NR_BUCATI: "No. pieces",
    GREUTATE_TO: "Weight TO",
    GREUTATE_PESCAJ: "Weight draft",
    PESCAJ: "Draft",
    EXPEDITOR: "Sender",
    DESTINATAR: "Recipient",
    AVIZAT: "Advised",
    DENUMIRE_MARFA: "Goods name",
    ANEXE_SIGILII: "Attach seals",
    LOC_INTORCMIRE: "Making place",
    OBSERVATII: "Comments",
    INCARCA_DOCUMENT: "Upload file",
    GENEREAZA_PDF: "Generate pdf",
    SALVEAZA: "Save",
    SALVAT: "Saved",
    INAPOI: "Back",
    STARE: "State",
    POZITIE: "Position",
    PROPULSOR: "Pusher",
    AMENAJARE: "Improvment", //todo de gasit traducere buna
    TIP_NAVA: "Ship type",
    TOATE: "All",
    NEPROPULSATE: "Non-powered",
    PROPRIETAR: "Owner",
    OPERATOR: "Operator",
    DENUMIRE: "Name",
    ANR: "ANR",
    ENI: "ENI",
    CAPACITATE: "Capacity",
    DATA_START: "Start Date",
    DATA_END: "End Date",
    NAVA_NEPROPULSATA: "Non-power ship",
    ACTIVITATE: "Activity",
    ADD_JURNAL: "Add register",
    ACTIVITATE_SIMULTANA: "Simultaneous activity",
    DIRECTIE: "Direction",
    DE_LA: "From",
    PANA_LA: "To",
    STERGE: "Delete",
    BARJA: "Barge",
    PESCAJ_MAXIM: "Max draft",
    JURNAL_PLAN: "Plan register",
    NUMAR_JURNAL: "Register number",
    AVAL: "Downstream",
    AMONTE: "Upstream",
    POZITIE_START: "Start position",
    ORA_STOP: "Stop hour",
    DURATA: "Time",
    JURNAL_UNITATI: "Log units",
    ADAUGA: "Add",
    IMPORT: "Import",
    ADD_JURNAL_UNITATE: "Add unit log",
    ADD_MENTENANTA: "Add maitenance",
    DATA_CONSTATARE: "Observation date",
    DATA_REZOLVARE: "Rezolved date",
    LOC_ACTIVITATE: "Activity place",
    REZOLVAT: "Fixed",
    CURATAT_DE: "Cleaned by",
    TIP_CURATENIE: "Maintenance type",
    STATUS: "State",
    CURAT: "Clean",
    MURDAR: "Dirty",
    DATA_CURATENIE: "Cleaned day",
    DEPARTAMENT: "Department",
    CHIMICE: "Chemicals",
    METALE: "Metals",
    AGRICOLE: "Agriculturals",
    CLIENT: "Client",
    VALIDEAZA_MODIFICARI: "Validate changes",
    NUMAR: "Number",
    DATA_ADAUGARE: "Added date",
    MEDIU: "Transmission way",
    PARTENER: "Partner",
    CLIENTUL_CLIENTULUI: "Client of client",
    TARA_PLECARE: "Departure country",
    TARA_SOSIRE: "Arrival country",
    ADAUGARE_PLAN: "Add planning",
    TIP_PRESTATIE: "Benefit type",
    VALABILITATE: "Valability",
    CARACTERISTICI_MARFA: "Goods characteristics",
    NUMAR_LOTURI: "Lot number",
    DETALIU_MARFA: "Goods details",
    INCARCARE: "Load",
    DESCARCARE: "Unload",
    PORT_INCARCARE: "Departure port",
    DANA_INCARCARE: "Departure dock",
    IEY_CAN_START: "Ley/can start",
    IEY_CAN_STOP: "Ley/can stop",
    CU_MANEVRA_INCARCARE: "With loading maneuver",
    FIRMA_MANEVRA: "Maneuver firm",
    CU_SURVERY_INCARCARE: "With loading survey",
    FIRMA_SURVEY: "Survey firm",
    DANA_SOSIRE: "Arrival dock",
    ETA_START: "ETA Start",
    ETA_STOP: "ETA Stop",
    CU_MANEVRA_DESCARCARE: "With unloading maneuver",
    CU_SURVEY_DESCARCARE: "With unloading survey",
    VIZUALIZARE_BARJE: "View Barges",
    ACTIVITATI_BARJE: "Barge activities",
    ACTIVITATI_ALTE_NAVE: "Other ships activities",
    ACTIVITATI_CONVOAIE: "Convoy activities",
    CONVOAIE_AVAL: "Downstream convoys",
    CONVOAIE_AMONTE: "Upstream convoys",
    ACTIVITATI_PROPULSATE: "Propelled activities",
    ACTIVITATI_PROPULSOARE: "Propellers activities",
    PROPULSOARE: "Propellers",
    NOMINARE: "Nomination",
    ORA: "Hour",
    EDITARE_ACTIVITATE: "Edit activity",
    AFISARE_HARTA: "Show on map",
    MOMENT: "Moment",
    PORT: "Port",
    COTA: "Elevation",
    TENDINTA: "Trend",
    SISTEM: "System",
    GRAVITATE: "Gravity",
    USOR: "Light",
    GRAV: "Serious",
    INDISPONIBIL: "Unavailable",
    DATA_RAPORTARE: "Raported date",
    REPARAT_LA_BORD: "Fixed on board",
    SANTIER_NAVAL: "Shipyard",
    RAPORTAT_DE: "Reported by",
    ADD_PROBLEMA: "Add problem",
    CONSTATARE: "Observation",
    PLANIFICARE: "Planning",
    IN_REPARATIE: "In repair",
    REPARATIE_FINALIZATA: "Repair finished",
    DATA_START_PLANIFICARE: "Planning start date",
    DATA_STOP_PLANIFICARE: "Planning stop date",
    DATA_START_REPARATIE: "Repair start date",
    DATA_STOP_REPARATIE: "Repair stop date",
    LUNI_GARANTIE: "Months warranty",
    PROBLEMA: "Problem",
    NR_CONTRACT: "No. contract",
    DESTINATIE: "Destination",
    ETA: "ETA",
    DOCUMENTE: "Documents",
    DETALII: "Details",
    NAVE: "Ships",
    SITUATIE: "Situation",
    IMPINGATOR: "Proppeler",
    DURATA_CORECTATA: "Corrected spent time",
    AGENT: "Agent",
    PERIOADA: "Period",
    ZI: "Day",
    WORKDATASTART: "WORKDATASTART",
    FARA_NAVE: "No ships were found",
    FARA_DOCUMENTE: "Document was not generated",
    SELECTATI_DATA: "Select date",
    CAUTA: "Search...",
    AFISARE_COLOANE: "Display columns",
  },
};

export const getString = (stringId, language) => {
  if (!language) {
    console.warn("getString, language is undefined");
    language = "ro";
  }
  if (!strings[language][stringId]) {
    const alternativeLanguage = language === "en" ? "ar" : "en";
    if (!strings[alternativeLanguage][stringId]) {
      console.warn("getString, string not found in any language. ID: ", stringId);
      return "";
    }
    console.warn(`getString, string not found in ${language}. ID: `, stringId);
    return strings[alternativeLanguage][stringId];
  }
  return strings[language][stringId];
};

export const languageSwitcher = (pLanguage) => {
  localStorage.setItem("navrom_language", pLanguage);
};

export const getLanguage = () => {
  let lang = "ro";
  try {
    const language = userService.getLocalStorage("navrom_language");
    if (language !== "null" && language !== null) {
      lang = language;
    } else {
      localStorage.setItem("navrom_language", lang);
    }
  } catch (e) {
    console.log(e);
    localStorage.setItem("navrom_language", lang);
  }
  return lang;
};
