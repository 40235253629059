import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import "./App.scss";
import { PrivateRoute } from "./views/Base/PrivateRoute";
import { userService } from "./services";
import { constUrl } from "./services/const"

const loading = () => <div className="animated fadeIn pt-3 text-center">Se incarca...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading,
});

const ForgotPassword = Loadable({
  loader: () => import("./views/Pages/Login/ForgotPassword"),
  loading,
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading,
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading,
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading,
});

const UrlSign = Loadable({
  loader: () => import("./views/Pages/UrlSign"),
  loading,
});

class App extends Component {
  componentDidMount() {
    let root = document.documentElement;
    root.classList.remove("small");
    root.classList.remove("normal");
    root.classList.remove("large");
    root.classList.remove("extra");
    try {
      const fontsize = userService.getPreferenceValue("fontSize", false);
      constUrl.pref_font.map((item) => {
        if (item.label === fontsize) {
          root.style.fontSize = `${item.value}px`;
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/forgotpassword" name="Forgot Password" component={ForgotPassword} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />

          <PrivateRoute exact path="/urlsign" name="URL Sign test" component={UrlSign} />

          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
