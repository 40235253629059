import { userService } from "./user.service";
import { constUrl } from "./const";
import { authService } from "./auth.service";

export const postService = {
  createNavrom,
  updateNavrom,
};

async function createNavrom(url, data, method = "POST", sign = true) {
  if (sign) {
    const si = url.indexOf("?");
    const signature = authService.getSignature(url);
    url += (si >= 0 ? "&" : "?") + "session_signature=" + signature.signature;
  }
  url = constUrl.MORMOT_URL + url;

  const response = await fetch(url, {
    method: method,
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    return -1;
  } else return response;
}

async function updateNavrom(url, data, method = "PUT", sign = true) {
  if (sign) {
    const si = url.indexOf("?");
    const signature = authService.getSignature(url);
    url += (si >= 0 ? "&" : "?") + "session_signature=" + signature.signature;
  }
  url = constUrl.MORMOT_URL + url;

  const response = await fetch(url, {
    method: method,
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    return -1;
  } else return response;
}
