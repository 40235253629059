const valueToOption = <T>(labelKey: string, valueKey: string, item: T, array: T[]) => {
  const foundItem = array.find((i) => i[valueKey] === item);

  if (foundItem) {
    return { value: foundItem[valueKey], label: foundItem[labelKey] };
  }

  return null;
};

const hasValue = <T>(obj: T, value: string, searcheableKeys: string[]): boolean => {
  if (value === "") return true;

  if (searcheableKeys.length > 0) {
    for (let key of searcheableKeys) {
      if (obj[key] && obj[key].toString().toLowerCase().includes(value.toString().toLowerCase())) return true;
    }

    return false;
  } else {
    const keys = Object.keys(obj);

    for (let key of keys) {
      const val = obj[key];

      if (val) {
        if (typeof val === "object") {
          if (hasValue(val, value, searcheableKeys)) return true;
        } else if (Array.isArray(val)) {
          for (let v of val) {
            if (hasValue(v, value, searcheableKeys)) return true;
          }
        } else {
          if (val.toString().toLowerCase().includes(value.toString().toLowerCase())) return true;
        }
      }
    }

    return false;
  }
};

const searchByKeys = <T>(initial: T[], value: string, keys: string[] = []) => {
  return initial.filter((item) => hasValue<T>(item, value, keys));
};

export const filtersService = { valueToOption, searchByKeys };
