// API Ports
// Test
//const APIport = 8889;
// Prod
const APIport = 8899;

// APP URLs
// Heroku
// const APP_URL = "https://dev-dispecerat-navrom.herokuapp.com/";
// Test Server
//const APP_URL = "https://test-dispecerat.navrom.ro/";
// Prod Server
const APP_URL = "https://dispecerat.navrom.ro/";

// TEST
// export const MORMOT_URL = "https://dbextern-test.navrom.ro:" + APIport + "/";
// const TIMESTAMP_URL = "https://dbextern-test.navrom.ro:" + APIport + "/root/Timestamp";
// PROD
export const MORMOT_URL = "https://dbextern.navrom.ro:" + APIport + "/";
const TIMESTAMP_URL = "https://dbextern.navrom.ro:" + APIport + "/root/Timestamp";

const signUrlGetNaveInConvoi = "root/TableView/GetNaveInConvoi";
const signUrlAmenajare = "root/TableView/GetAmenajare";
const signUrlStare = "root/Cod/?SELECT=ID,COD,Text2,Descriere&WHERE=TIP%3D%27ACT%27";
const signUrlStareDescriere = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27ACT%27";
const signUrlMarfa = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27MAR%27";
const signUrlMarfaTMF = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27TMF%27";
const signUrlTara = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27ZON%27";
const signUrlTipDocument = "root/Cod/?SELECT=*&WHERE=TIP%3D%27NTD%27";
const signUrlCodNac = "root/Cod/?SELECT=*&WHERE=TIP%3D%27NAC%27+and+Text1+like+%27%25P%25%27";
const signUrlCodTAS = "root/Cod/?SELECT=*&WHERE=TIP%3D%27TAS%27";
const signUrlCodNacP = "root/Cod/?SELECT=*&WHERE=TIP%3D%27NAC%27+and+Text1%3D%27N%27";
const signUrlCodNacSimplu = "root/Cod/?SELECT=*&WHERE=TIP%3D%27NAC%27";
const signUrlCodSof = "root/Cod/?SELECT=*&WHERE=TIP%3D%27SOF%27";
const signUrlGetActivitatePrincipala =
  "root/Cod/?SELECT=DISTINCT(Text2)&WHERE=TIP%3D%27NAC%27+and+Text1+like+%27%25P%25%27";

const signUrlDetaliuMarfa = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27SMF%27";
const signUrlTipPrestatie = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27CPO%27";
const signUrlGetActivitate = "root/TableView/GetActivitatePlan";
const signUrlGetActivitatePlan = "root/TableView/GetActivitatePlan";
const signUrlGetActivitateBarja = "root/TableView/GetActivitatePlan?tipNava=B";
const signUrlGetActivitateConvoi = "root/TableView/GetActivitatePlan?tipNava=C";
const signUrlGetActivitatePropulsate = "root/TableView/GetActivitatePlan?tipNava=P";
const signUrlGetActivitateAlte = "root/TableView/GetActivitatePlan?tipNava=A";
const signUrlGetPermisiuni = "root/TableView/GetPermisiuni";
const signUrlGetMFFisa = "root/TableView/GetMFFisa";
const signUrlTipNava = "root/TableView/GetTipNave";
const signUrlOperativCerere = "root/TableView/GetOperativCerere";
const signUrlOperativCererePost = "root/OperativCerere";
//const signUrlOperativCerere = "root/OperativCerere/?SELECT=*";
const signUrlPartener = "root/Partener/?SELECT=ID,NUME&SORT=NUME";
const signUrlLocatie = "root/Locatii/?SELECT=*";
const signUrlLocatiiCuListareCota = "root/Locatii/?SELECT=*&WHERE=CuListareCota%3d1";
const signUrlStareNava = "root/TableView/GetStareNava";
const signUrlGetActivitatePermisa = "root/TableView/GetActivitatePermisa";
const signUrlCotaApe = "root/TableView/GetCoteApe";
const signUrlPropulsoare = "root/TableView/GetListaPropulsoare";
const signUrlIstoricActivitate = "root/TableView/GetIstoric";
const signUrlStarePost = "root/STARE";
const signUrlSTARELOC = "root/STARELOC";
const session_time = 1800000;
const minus_rezolutie = 190;
const signUrlMfCuratenie = "root/TableView/GetMFCuratenie";
const signUrlMfCurateniePost = "root/MFCuratenie";
const signUrlMfProblema = "root/TableView/GetMFProblema";
const signUrlMfProblemaPost = "root/MFPROBLEMA";
const signUrlSistem = "root/Cod/?SELECT=ID,COD,DESCRIERE&WHERE=TIP%3D%27SIS%27";
const signUrlPersoana = "root/Persoana?SELECT=ID,NUME,PRENUME&SORT=NUME";
const signUrlGetLastModifOpCer = "root/TableView/GetLastModifOpCer";
const signUrlGetLastViewOpCer = "root/TableView/GetLastViewOpCer";
const signClassSQLUserUltimVazutPost = "root/UserUltimVazut";
const dateFormatNavrom = 'yyyy-mm-dd"T"HH:MM:ss';
const dateFormatFormular = "dd.MM.yyyy";
const signUrlUser = "root/Utilizator/";
const signUrlGetLastNumberDep = "root/TableView/GetLastNumberDep";
const signUrlGetJurnalBord = "root/TableView/GetJurnalBord";
const signUrlGetJurnalUnitati = "root/TableView/GetJurnalUnitati";
const signUrlGetJurnalPlan = "root/TableView/GetJurnalPlan";
const signUrlGetLastJurnalPlan = "root/JurnalBord/";
const signUrlGetAproxLocation = "root/TableView/GetAproxLocation";
const signUrlJurnalUnitatePost = "root/JurnalUnitati";
const signUrlJurnalBordPost = "root/JurnalBord";
const signUrlJurnalMasinaPost = "root/JurnalMasina";
const signUrlJurnalMasina = "root/TableView/GetJurnalMasina";
const signUrlGetJurnalStoc = "root/TableView/GetJurnalStoc";
const signUrlActeNava = "root/TableView/GetDocumenteTransport";
const signUrlActeNavaPost = "root/DocTransport";
const signUrlMFPoza = "root/MFPoza";
const signUrlPlanTransport = "root/TableView/GetNavaAlocataOperativ";
const signUrlGetNavaAlocataOperativ = "root/TableView/GetNavaAlocataOperativ";
const signUrlGetLocatii = "root/TableView/GetLocatii";
const signUrlGetNextBN = "root/TableView/GetNextBN";
const signUrlGetConvoaie = "root/TableView/GetConvoaie";
const signUrlLastJurnalMasina = "root/JurnalMasina/";
const signUrlGetNaveAlocateOperativ = "root/TableView/GetNaveAlocateOperativ";
const signUrlGetDocumenteTransportDetail = "root/TableView/GetDocumenteTransportDetail";
const signUrlDocTransportDetailsPost = "root/DocTransportDetail";
const signUrlGetImpingatoare_situatie = "root/TableView/GetSituatieFlota_Impingatoare";
const signUrlGetBarje_situatie = "root/TableView/GetSituatieFlota_Barje";
const signUrlGetBarje_reparatii = "root/TableView/GetSituatieFlota_Reparatii";
const signUrlNavaAlocataOperativPost = "root/NavaAlocataOperativ";
const signUrlGetSumarInfoNava = "root/TableView/GetSumarInfoNava";
const signUrlGetNaveAlocate = "root/TableView/GetNaveAlocate";
const signUrlIstoric = "root/TableView/GetIstoricNave";
const signUrlGetIstoricNaveCount = "root/TableView/GetIstoricNaveCount";
const signUrlGetActeInf = "root/TableView/GetActeNava";
const signUrlGetUtliziatorPref = "root/UtilizatorPref/?SELECT=*&WHERE=UtilizatorID%3D";
const signUrlGetUtliziatorPrefPost = "root/UtilizatorPref";
const signUrlGetExpirari = "root/TableView/GetActeNava?pDataReferinta=0";
const signUrlGetCod = "root/Cod?SELECT=ID,DESCRIERE&WHERE=TIP+LIKE+%27OPM%27";
const signUrlSalveaza = "root/TableView/SalveazaActivitate";
const signUrlGetOreStationareInPort = "root/TableView/GetOreStationareInPort";
const debugActivity = false;
const defaultResultRow = 20;
const prefixLocal = "THTLocal";

const modalStyle = {
  content: {
    top: "7%",
    left: "20%",
    right: "5%",
    bottom: "auto",
  },
  position: "fixed",
};

const modalStyleMobile = {
  content: {
    top: "10%",
    left: "1%",
    right: "1%",
  },
};

const pref_dict = [
  { label: "marfa", label_db: "INFO_MARFA_BIT", value: 1 },
  { label: "cantitate", label_db: "INFO_CANTITATE_BIT", value: 2 },
  { label: "stare", label_db: "INFO_STARE_BIT", value: 4 },
  { label: "observatii", label_db: "INFO_OBSERVATII_BIT", value: 8 },
  { label: "contract", label_db: "INFO_CONTRACT", value: 16 },
  { label: "amenajare", label_db: "INFO_AMENAJARE", value: 32 },
  { label: "propulsor", label_db: "INFO_PROPULSOR", value: 64 },
  { label: "locatie", label_db: "INFO_LOCATIE", value: 128 },
  { label: "destinatie", label_db: "INFO_DESTINATIE", value: 256 },
  { label: "eta", label_db: "INFO_ETA", value: 512 },
  { label: "pescaj", label_db: "INFO_PESCAJMAXIM", value: 1024 },
  { label: "client", label_db: "INFO_CLIENT", value: 2048 },
  { label: "documente", label_db: "INFO_SHOWDOC", value: 4096 },
];

const pref_font = [
  { label: "Small", value: 12 },
  { label: "Normal", value: 16 },
  { label: "Large", value: 18 },
  { label: "Extra", value: 20 },
];

export const constUrl = {
  MORMOT_URL,
  TIMESTAMP_URL,
  APP_URL,
  signUrlGetNaveInConvoi,
  signUrlGetActivitatePlan,
  signUrlAmenajare,
  signUrlStare,
  signUrlMarfa,
  signUrlGetActivitate,
  signUrlGetActivitateBarja,
  signUrlGetActivitateConvoi,
  signUrlGetActivitatePropulsate,
  signUrlGetActivitateAlte,
  signUrlDetaliuMarfa,
  signUrlTipPrestatie,
  signUrlMarfaTMF,
  signUrlTara,
  signUrlDocTransportDetailsPost,
  signUrlGetPermisiuni,
  signUrlGetMFFisa,
  signUrlTipNava,
  signUrlOperativCerere,
  signUrlOperativCererePost,
  signUrlPartener,
  signUrlLocatie,
  signUrlStareNava,
  signUrlGetActivitatePermisa,
  signUrlCotaApe,
  signUrlPropulsoare,
  signUrlIstoricActivitate,
  signUrlStarePost,
  session_time,
  minus_rezolutie,
  signUrlMfCuratenie,
  signUrlMfProblema,
  signUrlMfProblemaPost,
  signUrlSistem,
  signUrlPersoana,
  signUrlGetLastModifOpCer,
  signUrlGetLastViewOpCer,
  signUrlMfCurateniePost,
  signClassSQLUserUltimVazutPost,
  signUrlSTARELOC,
  dateFormatNavrom,
  signUrlUser,
  signUrlGetLastNumberDep,
  signUrlCodNac,
  signUrlGetJurnalBord,
  signUrlGetJurnalUnitati,
  signUrlGetJurnalPlan,
  signUrlGetLastJurnalPlan,
  signUrlGetAproxLocation,
  debugActivity,
  defaultResultRow,
  signUrlCodNacP,
  signUrlJurnalUnitatePost,
  signUrlJurnalBordPost,
  signUrlJurnalMasina,
  signUrlGetActivitatePrincipala,
  signUrlCodNacSimplu,
  signUrlActeNavaPost,
  prefixLocal,
  signUrlActeNava,
  signUrlTipDocument,
  signUrlPlanTransport,
  signUrlMFPoza,
  signUrlGetNaveAlocateOperativ,
  signUrlGetLocatii,
  signUrlGetNextBN,
  signUrlGetConvoaie,
  signUrlGetImpingatoare_situatie,
  signUrlLastJurnalMasina,
  signUrlJurnalMasinaPost,
  dateFormatFormular,
  signUrlGetNavaAlocataOperativ,
  signUrlCodSof,
  signUrlGetDocumenteTransportDetail,
  signUrlGetBarje_situatie,
  signUrlGetBarje_reparatii,
  modalStyle,
  modalStyleMobile,
  signUrlNavaAlocataOperativPost,
  signUrlGetSumarInfoNava,
  signUrlGetNaveAlocate,
  signUrlIstoric,
  signUrlGetIstoricNaveCount,
  signUrlGetActeInf,
  signUrlGetUtliziatorPref,
  signUrlGetUtliziatorPrefPost,
  pref_dict,
  pref_font,
  signUrlLocatiiCuListareCota,
  signUrlGetExpirari,
  signUrlCodTAS,
  signUrlGetJurnalStoc,
  signUrlStareDescriere,
  signUrlGetCod,
  signUrlSalveaza,
  signUrlGetOreStationareInPort,
};
