import { userService } from "./user.service";
import { constUrl } from "./const";
import { authService } from "./auth.service";

export const fetchService = {
  fetchNavrom,
  fetchNavromDoc,
  signUrl,
  fetch_rez,
};

async function signUrl(url) {
  const si = url.indexOf("?");
  const signature = authService.getSignature(url);
  url += (si >= 0 ? "&" : "?") + "session_signature=" + signature.signature;
  url = constUrl.MORMOT_URL + url;
  return url;
}

async function fetchNavrom(url, sign = true, headers = {}) {
  if (sign) {
    const si = url.indexOf("?");
    const signature = authService.getSignature(url);
    url += (si >= 0 ? "&" : "?") + "session_signature=" + signature.signature;
  }

  url = constUrl.MORMOT_URL + url;

  const response = await fetch(url, {
    headers: headers,
  });
  if (!response.ok) {
    userService.logout();
    this.props.history.push("/login");
    //window.location = '/login';
  } else return response;
}

async function fetchNavromDoc(url, sign = true, headers = {}) {
  if (sign) {
    const si = url.indexOf("?");
    const signature = authService.getSignature(url);
    url += (si >= 0 ? "&" : "?") + "session_signature=" + signature.signature;
  }

  url = constUrl.MORMOT_URL + url;
  return fetch(url, { headers })
    .then((response) => {
      if (!response.ok) {
        userService.logout();
        this.props.history.push("/login");
        throw new Error("Fetch failed");
      }
      return response.blob();
    })
    .then((blob) => {
      return new Response(blob);
    })
    .catch((error) => {
      console.error("There has been a problem with your fetch operation:", error);
    });
}

async function fetch_rez(url) {
  let rez = {};
  try {
    const response = await fetchService.fetchNavrom(url);
    if (response.ok === false) {
      rez = null;
      this.setState({ error: true });
    } else {
      rez = await response.json();
    }
  } catch (error) {
    rez = null;
    console.log(error);
  }
  return rez;
}
