import { MORMOT_URL, constUrl } from "./const";
import { fetchService } from "./fetch.service";
import crc32 from "crc/crc32";

import shajs from "sha.js";

const AUTH_URL = `${MORMOT_URL}root/auth`;

async function get_permisiuni() {
  /*
      const permisiuni = ["nave", "harta", "catalognave", "dispecerat", "activititati", "istoric", "rapoarte", "diverse", "actBarje", "actProp",
        "actAlte", "actDiverse", "istGeneral", "istUnitati", "istActivitate", "istModificari", "rapExpiraPermis", "rapBarjeCapace", "rapSituatieFlota",
        "rapSituatieRealizari", "divAvizari", "divCoteApe", "divPermise", "divSituatieConvoaie", "divRealizari", "planuri", "planAdd", "planMod", "planLst"];
        */
  const newPerms = [];
  try {
    const response = await fetchService.fetchNavrom(constUrl.signUrlGetPermisiuni);
    if (response.status === -1) {
    } else {
      const jsonRes = await response.json();
      for (let i = 0; i < jsonRes.length; i++) {
        newPerms.push(jsonRes[i].NumeObiect);
      }
      localStorage.setItem("permisiuni", JSON.stringify(newPerms));

      //window.location = '/'; //todo trebuie schimbat cu state (?) NAVROM-21
    }
  } catch (error) {
    console.log(error);
    localStorage.setItem("permisiuni", JSON.stringify(newPerms));
  }
}

const sha256 = (str: string) => {
  return shajs("sha256").update(str).digest("hex");
};

const getSession = async (username: string, password: string, hexCode: string): Promise<any> => {
  const clientNonce = new Date().getTime() / (1000 * 60 * 6);
  const clientNonceSha = sha256(clientNonce.toString());
  const passwordSha = sha256(`salt${password}`);
  const passField = sha256(`root${hexCode}${clientNonceSha}${username}${passwordSha}`);

  const res = await fetch(AUTH_URL + `?UserName=${username}&Password=${passField}&ClientNonce=${clientNonceSha}`);
  if (res.status !== 200) {
    logout();
  }

  const jsonRes = await res.json();
  jsonRes.passwordSha = passwordSha;

  return jsonRes;
};

const login = async (username: string, password: string) => {
  const res = await (await fetch(AUTH_URL + `?UserName=${username}`)).json();
  const clientHex = res.result;
  const session = await getSession(username, password, clientHex);
  if (session.result) {
    const splittedRes = session.result.split("+");
    const sessionId = splittedRes[0];
    const privateKey = splittedRes[1];

    localStorage.setItem("user", JSON.stringify(session));
    localStorage.setItem("navrom_password_hash", session.passwordSha);
    localStorage.setItem("navrom_session_id", sessionId);
    localStorage.setItem("navrom_private_key", privateKey);
    localStorage.setItem("navrom_latest_activity", Date.now().toString());
    await get_permisiuni();
  }
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("navrom_session_id");
  localStorage.removeItem("navrom_private_key");
  localStorage.removeItem("navrom_password_hash");
  localStorage.removeItem("permisiuni");
  localStorage.removeItem("navrom_latest_activity");
};

function dechex(number) {
  if (number < 0) {
    number = 0xffffffff + number + 1;
  }
  return parseInt(number, 10).toString(16);
}

const zerofill = (value: any, shift: number) => {
  return (value & 0x80000000 ? value | 0xffffffff00000000 : value & 0xffffffff) >> (shift & 0x1f);
};

const getSignature: (url: string) => {
  signature: string;
  crc: number;
} = (url) => {
  const time = new Date().getTime();
  const sessionId = localStorage.getItem("navrom_session_id");
  let privateKey = localStorage.getItem("navrom_private_key");
  const password = localStorage.getItem("navrom_password_hash");

  privateKey = `${privateKey}${password}`;

  const miliseconds = dechex(zerofill(time, 8)).padStart(8, "0");

  let signature = `${dechex(sessionId).padStart(8, "0")}${miliseconds}`;

  const crc = crc32(`${sessionId}+${privateKey}${miliseconds}${url}`);

  const signature_part = dechex(crc).padStart(8, "0");
  signature = `${signature}${signature_part}`;

  return {
    signature,
    crc,
  };
};

export const authService = {
  login,
  logout,
  getSignature,
};
