import { constUrl } from "./const";
//import moment from "../views/Diverse/DiverseProbleme";
import { authService } from "./auth.service";
import moment from "moment";
import { Preferences } from "components";

export const userService = {
  check_activity,
  getActiveUserId,
  getNumberOfRows,
  getPreferenceValue,
  GetTimeMoment,
  return_permisiuni,
  GetDataMoment,
  getData,
  GetDiferenceMinutes,
  getLocalStorage,
  dataURItoBlob,
  getDayName,
  getBit,
  getRandomColor,
  compareValues,
  logout,
};

function getBit(ID, position) {
  return (ID >> position) & 1;
}

function return_permisiuni() {
  let newPerms = [];
  try {
    const { signature } = authService.getSignature(constUrl.signUrlGetPermisiuni);

    if (signature) {
      let url = constUrl.MORMOT_URL + constUrl.signUrlGetPermisiuni;
      url += "?session_signature=" + signature;
      fetch(url)
        .then((result) => {
          if (result.status === 403) {
            console.log("Eroare user service linia 93;");
            return [];
          } else {
            return result.json();
          }
        })
        .then((result) => {
          for (var i = 0; i < result.length; i++) {
            newPerms.push(result[i].NumeObiect);
          }
          return newPerms;
        });
    }
  } catch (error) {
    console.log(error);
    return newPerms;
  }
}

function logout() {
  authService.logout();
}

function check_activity() {
  let latest_activity = parseInt(localStorage.getItem("navrom_latest_activity"));
  if (latest_activity === null) {
    return true;
  } else {
    if (Date.now() - latest_activity < constUrl.session_time) {
      localStorage.setItem("navrom_latest_activity", Date.now().toString());
      return true;
    } else {
      return false;
    }
  }
}

function getActiveUserId() {
  let userId = JSON.parse(localStorage.getItem("user")).logonid;
  return userId;
}

function getNumberOfRows() {
  let userId = this.getActiveUserId();
  let setari = localStorage.getItem(userId);
  let nrRows = 10;
  try {
    const values = JSON.parse(setari).preferinte as Preferences;
    nrRows = values.nrRanduri;
  } catch (e) {}
  return Number(nrRows);
}

function getPreferenceValue(key, boolean = true) {
  let userId = this.getActiveUserId();
  let setari = localStorage.getItem(userId);
  var rsp = 0;
  try {
    const values = JSON.parse(setari).preferinte as Preferences;
    rsp = values[key];
  } catch (e) {}
  if (boolean) {
    return rsp === 1 ? true : false;
  }
  return rsp;
}

function GetDataMoment(data) {
  let def_moment = moment("1899-12-29");
  let dt = moment(data).format("LL");
  let rez = moment(dt).diff(def_moment, "days");

  return rez;
}

function GetTimeMoment(total_sec) {
  let rez = total_sec / 86400;
  return rez;
}

function GetDiferenceMinutes(data_start, data_end) {
  return moment(data_end).diff(data_start, "minutes");
}

function getData() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return dd + "/" + mm + "/" + yyyy;
}

function getLocalStorage(item, cheie = null) {
  let value = localStorage.getItem(item);
  if (cheie == null) {
    return value;
  }
  return JSON.parse(value)[cheie];
}

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

function getDayName(dateStr, locale) {
  var date = new Date(dateStr);
  let a = date.toLocaleDateString(locale, { weekday: "short" });
  return a;
}

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}
