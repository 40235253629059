import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const GetPDF = (data) => {
  function get_string_denumire_imp(array, cu_nume = true) {
    let s = "";
    array.forEach((e) => {
      s += e.Denumire + "\n" + (cu_nume ? get_prescurtare(e.locatie) + "\n" : "");
    });
    return s;
  }

  function get_prescurtare(locatie) {
    let rez = locatie;
    if (locatie.toUpperCase() === "KOMARNO") {
      rez = "KNO.";
    } else if (locatie.toUpperCase() === "KOMAROM") {
      rez = "KOM.";
    } else if (locatie.toUpperCase() === "BRATISLAVA") {
      rez = "BRAT.";
    } else if (locatie.toUpperCase() === "ASCHACH") {
      rez = "ASCH.";
    } else if (locatie.toUpperCase() === "STRAUBING") {
      rez = "TRAUB.";
    } else if (locatie.toUpperCase() === "DEGENDORF") {
      rez = "DEGG.";
    } else if (locatie.toUpperCase() === "REGENSBURG") {
      rez = "REGENSB.";
    } else if (locatie.toUpperCase() === "KELHEIM") {
      rez = "KELH.";
    } else if (locatie.toUpperCase() === "POCHLARN") {
      rez = "POCH.";
    } else if (locatie.toUpperCase() === "KLIZSKA NEMA") {
      rez = "K.NEMA";
    } else if (locatie.toUpperCase() === "DUNAUJVAROS") {
      rez = "D.VAR";
    } else if (locatie.toUpperCase() === "DUNAFOLDVAR") {
      rez = "D.FVAR.";
    } else if (locatie.toUpperCase() === "DUNAVECSE") {
      rez = "D.VECSE";
    } else if (locatie.toUpperCase() === "BOGYISZLO") {
      rez = "BOGYS.";
    } else if (locatie.toUpperCase() === "NOVI SAD") {
      rez = "N.SAD";
    } else if (locatie.toUpperCase() === "PANCEVO") {
      rez = "PANC.";
    } else if (locatie.toUpperCase() === "VUKOVAR") {
      rez = "VUK.";
    } else if (locatie.toUpperCase() === "BELGRAD") {
      rez = "BELGR.";
    } else if (locatie.toUpperCase() === "PANCEVO") {
      rez = "PANC.";
    } else if (locatie.toUpperCase() === "BACKA PALANKA") {
      rez = "B.PAL.";
    } else if (locatie.toUpperCase() === "BOGOJEVO") {
      rez = "BOG.";
    } else if (locatie.toUpperCase() === "SMEDEREVO") {
      rez = "SMED.";
    } else if (locatie.toUpperCase() === "SVISTOV") {
      rez = "SVIST.";
    } else if (locatie.toUpperCase() === "SOMOVIT") {
      rez = "SOM.";
    } else if (locatie.toUpperCase() === "ORIAHOVO") {
      rez = "ORIAH";
    } else if (locatie.toUpperCase() === "KOZLODUI") {
      rez = "KOZLD.";
    }
    return rez;
  }

  function get_string_denumire_barje(array, den = "") {
    let s = "";
    array.forEach((e) => {
      let azi = new Date();
      let denumire = "";
      var newDate = new Date(azi.setTime(azi.getTime() + 7 * 86400000));

      // daca expira atunci pun numele in bold
      if (new Date(e.expiraTonaj) <= newDate) {
        s += "#";
      }
      denumire += e.Denumire;

      // informatii suplimentare despre barja
      if (e.TipNava.toUpperCase() === "SLEP") {
        denumire += "S";
      } else if (e.TipNava.toUpperCase() === "BARJA") {
        if (e.TipAmenajare && e.TipAmenajare.toUpperCase() === "AUTORUJ") denumire += "A";
        else if (
          e.TipAmenajare &&
          (e.TipAmenajare.toUpperCase() === "CAPACE" || e.TipAmenajare.toUpperCase() === "CAPACE F")
        )
          denumire += "C";
        else if (e.TipAmenajare && e.TipAmenajare.toUpperCase() === "TANK") denumire += "T";
      }

      //localitate
      if (e.zona !== "RO" || den === "DIVERSE") {
        denumire += " " + get_prescurtare(e.locatie);
      }
      //marfa
      denumire += " " + e.marfa;

      //cantitate
      if ((e.activitate === "SAD" && e.zona !== "RO") || (e.activitate === "ARM" && e.localitate === "AGIGEA")) {
        denumire += " " + e.CANTITATE;
      }

      //observatii
      if (
        (den === "DIVERSE" || e.zona === "RCC" || e.zona === "HU" || e.zona === "SE" || e.zona === "BU") &&
        (e.activitate === "ARM" || e.activitate === "ARV")
      ) {
        denumire += " " + e.NOMINARE + " " + (e.OBSERVATII || "").substring(0, 15);
      } else if (den === "DIVERSE" && e.acstare === "SAD") {
        denumire += " " + e.NOMINARE;
      } else if (
        (e.locatie === "GALATI" || e.locatie === "AGIGEA" || e.locatie === "DOCURI" || e.locatie === "BAZIN NOU") &&
        (e.activitate === "SSD" || e.activitate === "SSI")
      ) {
        denumire += " " + e.LocDetaliu + " " + e.NOMINARE + "-" + (e.OBSERVATII || "").substring(0, 15);
      } else {
        denumire += " " + e.NOMINARE + "-" + (e.OBSERVATII || "").substring(0, 15);
      }
      s += denumire + "\n";
    });
    return s;
  }

  function get_ore_stationare(array) {
    const naveStationate = array
      .filter((e) => e.ore_stationare)
      .map((e) => ({ nava: e.NumeNava, ore_stationare: e.ore_stationare }));

    let sub_7_zile_stationate = "";
    let intre_7_si_17_zile_stationate = "";
    let intre_17_si_27_zile_stationate = "";
    let peste_27_zile_stationate = "";

    let numar_sub_7 = 0;
    let numar_intre_7_si_17 = 0;
    let numar_intre_17_si_27 = 0;
    let numar_peste_27 = 0;

    naveStationate.forEach((element) => {
      const zileStationare = Math.floor(element.ore_stationare / 24);
      if (zileStationare < 7) {
        sub_7_zile_stationate += element.nava + " ";
        numar_sub_7++;
        if (numar_sub_7 % 5 === 0) sub_7_zile_stationate += "\n";
      } else if (zileStationare >= 7 && zileStationare < 17) {
        intre_7_si_17_zile_stationate += element.nava + " ";
        numar_intre_7_si_17++;
        if (numar_intre_7_si_17 % 5 === 0) intre_7_si_17_zile_stationate += "\n";
      } else if (zileStationare >= 17 && zileStationare < 27) {
        intre_17_si_27_zile_stationate += element.nava + " ";
        numar_intre_17_si_27++;
        if (numar_intre_17_si_27 % 5 === 0) intre_17_si_27_zile_stationate += "\n";
      } else if (zileStationare >= 27) {
        peste_27_zile_stationate += element.nava + " ";
        numar_peste_27++;
        if (numar_peste_27 % 5 === 0) peste_27_zile_stationate += "\n";
      }
    });

    return {
      sub_7_zile_stationate,
      intre_7_si_17_zile_stationate,
      intre_17_si_27_zile_stationate,
      peste_27_zile_stationate,
    };
  }

  var doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a3",
  });

  let imp_diverse = get_string_denumire_imp(data.impingatoare_diverse, true);
  let imp_sk_at_de = get_string_denumire_imp(data.impingatoare_sk_at_de);
  let imp_ungaria = get_string_denumire_imp(data.impingatoare_ungaria);
  let imp_serbia = get_string_denumire_imp(data.impingatoare_serbia);
  let imp_bulgaria = get_string_denumire_imp(data.impingatoare_bulgaria);
  // let imp_romania = get_string_denumire_imp(data.impingatoare_romania);

  //SAD -> ASTEAPTA DESCARCARE
  let barje_diverse_sad = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "SAD"),
    "DIVERSE"
  );
  let barje_sk_at_de_sad = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "SAD"));
  let barje_ungaria_sad = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "SAD"));
  let barje_serbia_sad = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "SAD"));
  let barje_bulgaria_sad = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "SAD"));

  //SSI -> SUB INCARCARE
  let barje_diverse_ssi = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "SSI"),
    "DIVERSE"
  );
  let barje_sk_at_de_ssi = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "SSI"));
  let barje_ungaria_ssi = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "SSI"));
  let barje_serbia_ssi = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "SSI"));
  let barje_bulgaria_ssi = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "SSI"));

  //SAI -> ASTEAPTA INCARCAREA
  let barje_diverse_sai = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "SAI"),
    "DIVERSE"
  );
  let barje_sk_at_de_sai = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "SAI"));
  let barje_ungaria_sai = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "SAI"));
  let barje_serbia_sai = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "SAI"));
  let barje_bulgaria_sai = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "SAI"));

  //SSD -> SUB DESCARCARE
  let barje_diverse_ssd = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "SSD"),
    "DIVERSE"
  );
  let barje_sk_at_de_ssd = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "SSD"));
  let barje_ungaria_ssd = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "SSD"));
  let barje_serbia_ssd = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "SSD"));
  let barje_bulgaria_ssd = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "SSD"));

  //ARM -> ASTEAPTA REMORCARE AMONTE
  let barje_diverse_arm = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "ARM"),
    "DIVERSE"
  );
  let barje_sk_at_de_arm = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "ARM"));
  let barje_ungaria_arm = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "ARM"));
  let barje_serbia_arm = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "ARM"));
  let barje_bulgaria_arm = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "ARM"));

  //ARV -> ASTEAPTA REMORCARE AVAL
  let barje_diverse_arv = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "ARV"),
    "DIVERSE"
  );
  let barje_sk_at_de_arv = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "ARV"));
  let barje_ungaria_arv = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "ARV"));
  let barje_serbia_arv = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "ARV"));
  let barje_bulgaria_arv = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "ARV"));

  //DIR -> ASTEAPTA REMORCARE AVAL
  let barje_diverse_dir = get_string_denumire_barje(
    data.barje_diverse.filter((obj) => obj.activitate === "DIR"),
    "DIVERSE"
  );
  let barje_sk_at_de_dir = get_string_denumire_barje(data.barje_sk_at_de.filter((obj) => obj.activitate === "DIR"));
  let barje_ungaria_dir = get_string_denumire_barje(data.barje_ungaria.filter((obj) => obj.activitate === "DIR"));
  let barje_serbia_dir = get_string_denumire_barje(data.barje_serbia.filter((obj) => obj.activitate === "DIR"));
  let barje_bulgaria_dir = get_string_denumire_barje(data.barje_bulgaria.filter((obj) => obj.activitate === "DIR"));

  // Ore stationare in port
  const ore_stationare = get_ore_stationare(data.ore_stationare);

  let linii = [];
  linii.push(
    [
      { content: "DIVERSE\n" + imp_diverse },
      { content: barje_diverse_sad },
      { content: barje_diverse_ssd },
      { content: barje_diverse_sai },
      { content: barje_diverse_ssi },
      { content: barje_diverse_arm },
      { content: barje_diverse_arv },
      { content: barje_diverse_dir },
    ],

    [
      { content: "SK-AT-DE\n" + imp_sk_at_de },
      { content: barje_sk_at_de_sad },
      { content: barje_sk_at_de_ssd },
      { content: barje_sk_at_de_sai },
      { content: barje_sk_at_de_ssi },
      { content: barje_sk_at_de_arm },
      { content: barje_sk_at_de_arv },
      { content: barje_sk_at_de_dir },
    ],

    [
      { content: "UNGARIA\n" + imp_ungaria },
      { content: barje_ungaria_sad },
      { content: barje_ungaria_ssd },
      { content: barje_ungaria_sai },
      { content: barje_ungaria_ssi },
      { content: barje_ungaria_arm },
      { content: barje_ungaria_arv },
      { content: barje_ungaria_dir },
    ],
    [
      { content: "SERBIA-HR\n" + imp_serbia },
      { content: barje_serbia_sad },
      { content: barje_serbia_ssd },
      { content: barje_serbia_sai },
      { content: barje_serbia_ssi },
      { content: barje_serbia_arm },
      { content: barje_serbia_arv },
      { content: barje_serbia_dir },
    ],
    [
      { content: "BULGARIA\n" + imp_bulgaria },
      { content: barje_bulgaria_sad },
      { content: barje_bulgaria_ssd },
      { content: barje_bulgaria_sai },
      { content: barje_bulgaria_ssi },
      { content: barje_bulgaria_arm },
      { content: barje_bulgaria_arv },
      { content: barje_bulgaria_dir },
    ]
  );

  data.localitati.forEach((e) => {
    var localitate = e;
    let adauga = false;
    let imp_oras = get_string_denumire_imp(
      data.impingatoare_romania.filter((obj) => obj.locatie === localitate),
      false
    );

    let barje_oras_sad = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "SAD" && obj.locatie === localitate)
    );
    let barje_oras_ssi = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "SSI" && obj.locatie === localitate)
    );
    let barje_oras_sai = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "SAI" && obj.locatie === localitate)
    );
    let barje_oras_ssd = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "SSD" && obj.locatie === localitate)
    );
    let barje_oras_arm = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "ARM" && obj.locatie === localitate)
    );
    let barje_oras_arv = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "ARV" && obj.locatie === localitate)
    );
    let barje_oras_dir = get_string_denumire_barje(
      data.barje_romania.filter((obj) => obj.activitate === "DIR" && obj.locatie === localitate)
    );
    if (
      imp_oras !== "" ||
      barje_oras_sad !== "" ||
      barje_oras_ssi !== "" ||
      barje_oras_sai !== "" ||
      barje_oras_ssd !== "" ||
      barje_oras_arm !== "" ||
      barje_oras_arv !== "" ||
      barje_oras_dir !== ""
    ) {
      adauga = true;
    }

    if (adauga) {
      linii.push([
        { content: localitate + "\n" + imp_oras },
        { content: barje_oras_sad },
        { content: barje_oras_ssd },
        { content: barje_oras_sai },
        { content: barje_oras_ssi },
        { content: barje_oras_arm },
        { content: barje_oras_arv },
        { content: barje_oras_dir },
      ]);
    }
  });

  doc.setFontSize(10);

  autoTable(doc, {
    head: [
      [
        "Locatie",
        "Asteapta Descarcare",
        "Sub Descarcare",
        "Asteapta Incarcare ",
        "Sub Incarcare",
        "Ast. Remorcare Amonte",
        "Ast. Remorcare Aval",
        "Dirijare",
      ],
    ],

    body: linii,
    startY: 7,
    tableLineColor: "black",
    tableLineWidth: 0.5,
    margin: { top: 5, bottom: 5, right: 5, left: 5 },
    styles: {
      lineColor: "black",
      lineWidth: 0.2,
      fontStyle: "normal",
      fontSize: 8,
      font: "Helvetica",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "white",
      textColor: "black",
    },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      textColor: "black",
      cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    alternateRowStyles: {
      fillColor: "white",
    },
    columnStyles: {
      0: { cellWidth: 30 },
    },
  });

  //convoaie

  let convoaie_aval_grouped = groupBy(data.convoaie_aval, (convoi) => convoi.PROPULSOR);

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  linii = [[{ content: "Convoaie Aval", colSpan: 8, styles: { fontStyle: "bold", fontSize: 10 } }]];
  let aaa = [];
  let j = 0;

  convoaie_aval_grouped.forEach((e) => {
    let text = e[0].PROPULSOR + " " + e[0].POZITIE + ` (${e[0].POZITIE_ANT})` + "\n";
    e.forEach((x) => {
      text += x.BARJA + " " + x.CODMARFA + "\n";
    });
    let bbb = { content: text };
    if (j < 8) {
      aaa.push(bbb);
      j++;
    } else {
      linii.push(aaa);
      aaa = [];
      aaa.push(bbb);
      j = 0;
    }
  });
  linii.push(aaa);

  //tabel
  autoTable(doc, {
    body: linii,
    tableLineColor: "black",
    tableLineWidth: 0.5,
    margin: { top: 5, bottom: 5, right: 5, left: 5 },

    styles: {
      lineColor: "black",
      lineWidth: 0.2,
      fontStyle: "normal",
      fontSize: 8,
      font: "Helvetica",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "white",
      textColor: "black",
    },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      textColor: "black",
      cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    alternateRowStyles: {
      fillColor: "white",
    },
    columnStyles: {
      0: { cellWidth: 50 },
    },
  });

  let convoaie_amonte_grouped = groupBy(data.convoaie_amonte, (convoi) => convoi.PROPULSOR);

  linii = [[{ content: "Convoaie Amonte", colSpan: 8, styles: { fontStyle: "bold", fontSize: 10 } }]];
  aaa = [];
  j = 0;

  convoaie_amonte_grouped.forEach((e) => {
    let text = e[0].PROPULSOR + " " + e[0].POZITIE + ` (${e[0].POZITIE_ANT})` + "\n";

    e.forEach((x) => {
      text += x.BARJA + " " + x.CODMARFA + "\n";
    });

    let bbb = { content: text };
    if (j < 8) {
      aaa.push(bbb);
      j++;
    } else {
      linii.push(aaa);
      aaa = [];
      aaa.push(bbb);
      j = 0;
    }
  });
  linii.push(aaa);

  //tabel
  autoTable(doc, {
    body: linii,
    tableLineColor: "black",
    tableLineWidth: 0.5,
    margin: { top: 5, bottom: 5, right: 5, left: 5 },

    styles: {
      lineColor: "black",
      lineWidth: 0.2,
      fontStyle: "normal",
      fontSize: 8,
      font: "Helvetica",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "white",
      textColor: "black",
    },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      textColor: "black",
      cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    alternateRowStyles: {
      fillColor: "white",
    },
    columnStyles: {
      0: { cellWidth: 50 },
    },
  });

  let barje_reparatii_grouped = groupBy(data.barje_reparatii, (brj) => brj.locatie);

  linii = [];

  barje_reparatii_grouped.forEach((e) => {
    aaa = [];
    let bbb;
    let text = e[0].locatie;
    bbb = { content: text };
    aaa.push(bbb);
    let text2 = "";
    j = 0;
    e.forEach((x) => {
      text2 += x.Barja + "   ";
      j++;
      if (j > 5) {
        text2 += "\n";
        j = 0;
      }
    });
    bbb = { content: text2 };
    aaa.push(bbb);
    linii.push(aaa);
  });

  autoTable(doc, {
    head: [["Barje in reparatii\nLocalitate", "\nBarje"]],
    body: linii,
    tableLineColor: "black",
    tableLineWidth: 0.5,
    margin: { top: 5, bottom: 5, right: 5, left: 5 },

    styles: {
      lineColor: "black",
      lineWidth: 0.2,
      fontStyle: "normal",
      fontSize: 8,
      font: "Helvetica",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "white",
      textColor: "black",
      fontStyle: "bold",
    },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      textColor: "black",
      cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    alternateRowStyles: {
      fillColor: "white",
    },
    columnStyles: {
      0: { cellWidth: 50 },
    },
  });

  linii = [];
  linii.push([
    { content: "" },
    { content: ore_stationare.sub_7_zile_stationate },
    { content: ore_stationare.intre_7_si_17_zile_stationate },
    { content: ore_stationare.intre_17_si_27_zile_stationate },
    { content: ore_stationare.peste_27_zile_stationate },
  ]);

  autoTable(doc, {
    head: [["Zile stationare in port Agigea", "Sub 7 zile", "7-17 zile", "17-27", "Peste 27 de zile"]],
    body: linii,
    tableLineColor: "black",
    tableLineWidth: 0.5,
    margin: { top: 5, bottom: 5, right: 5, left: 5 },

    styles: {
      lineColor: "black",
      lineWidth: 0.2,
      fontStyle: "normal",
      fontSize: 8,
      font: "Helvetica",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "white",
      textColor: "black",
      fontStyle: "bold",
    },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      textColor: "black",
      cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    alternateRowStyles: {
      fillColor: "white",
    },
    columnStyles: {
      0: { cellWidth: 50 },
    },
  });

  const pageCount = doc.internal.pages.length;
  // For each page, print the page number and the total pages
  for (var i = 1; i <= pageCount - 1; i++) {
    // Go to page i
    doc.setPage(i);
    //Print Page 1 of 4 for example
    doc.text("Pag " + String(i) + " din " + String(pageCount - 1), 210 - 2, 297 - 2, null, null);
  }

  doc.setPage(1);
  const formattedDate = new Date().toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  doc.text(formattedDate, 10, 5, null, null);

  return doc;
};

export default GetPDF;
